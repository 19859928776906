/*global arrayUniqueFilter, localStore, scrollToElement*/
import { createStore } from 'vuex';
import ajax from '@/ajax';
import jwtDecode from 'jwt-decode';

export default createStore({
  state: {
    jwt: JSON.parse(localStorage.getItem('jwt')),
    refreshTimeOutTask: null,
    flagsBaseUrl: null,
    countries: null,
    uaeStates: null,
    user: JSON.parse(localStorage.getItem('user')),
    addresses: null,
    quote: {
      params: JSON.parse(localStorage.getItem('quoteParams')),
      services: [],
      deliveryService: JSON.parse(localStorage.getItem('quoteDeliveryService')),
    },
    booking: {
      prevUnlockedStep: 'bookingCollection',
      unlockedSteps: ['bookingCollection'],
      readyForPayment: false,
      data: {
        collectionAddress: null,
        collectionTime: null,
        destinationAddress: null,
        packages: [],
        deliveryService: null,
        confirmation: null,
        contactForInsurance: null,
      },
    },
    afterLoginRedirect: null,
    orders: null,
    transactions: null,
    locales: null
  },
  mutations: {
    setJwt(state, jwt) {
      const decodedJwt = jwtDecode(jwt.token);
      state.jwt = Object.assign({}, jwt, { expires: decodedJwt.exp });
      localStorage.setItem('jwt', JSON.stringify(state.jwt));
    },
    removeJwt(state) {
      state.jwt = null;
      localStorage.removeItem('jwt');
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    removeUser(state)
    {
        state.user = null;
        localStorage.removeItem('user');
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setFlagsBaseUrl(state, flagsBaseUrl) {
      state.flagsBaseUrl = flagsBaseUrl;
    },
    setUAEStates(state, states) {
      state.uaeStates = states;
    },
    setPackageTypes(state, packageTypes) {
      state.packageTypes = packageTypes;
    },
    setRefreshTimeOutTask(state, refreshTimeOutTask) {
      state.refreshTimeOutTask = refreshTimeOutTask;
    },
    setAddresses(state, addresses) {
      state.addresses = addresses;
    },
    setBookingCollectionAddress(state, collectionAddress) {
      state.booking.data.collectionAddress = collectionAddress;
    },
    setBookingCollectionTime(state, collectionTime) {
      state.booking.data.collectionTime = collectionTime;
    },
    setBookingDestinationAddress(state, destinationAddress) {
      state.booking.data.destinationAddress = destinationAddress;
    },
    /* setBookingStep(state, step) {
      state.booking.step = step;
    }, */
    addUnlockedStep(state, step) {
      state.booking.prevUnlockedStep = step;
      state.booking.unlockedSteps.push(step);
      state.booking.unlockedSteps = state.booking.unlockedSteps.filter(arrayUniqueFilter);
    },
    removeUnlockedStep(state, step) {
      const index = state.booking.unlockedSteps.indexOf(step);
      if (index > -1) {
        state.booking.unlockedSteps.splice(index, 1);
      }

      state.booking.prevUnlockedStep = state.booking.unlockedSteps[state.booking.unlockedSteps.length - 1];
    },
    setQuoteParams(state, params) {
      state.quote.params = params;
    },
    setQuoteDeliveryService(state, deliveryService) {
      state.quote.deliveryService = deliveryService;
    },
    setBookingDeliveryService(state, deliveryService) {
      state.booking.data.deliveryService = deliveryService;
    },
    addBookingPackage(state, pkg) {
      //console.log('adding');
      state.booking.data.packages.push(pkg);
    },
    updateBookingPackage(state, data) {
      //console.log('updating');
      state.booking.data.packages[data.index] = data.pkg;
    },
    deleteBookingPackage(state, index) {
      //console.log('deleting');
      state.booking.data.packages.splice(index, 1);
    },
    setBookingConfirmation(state, value) {
      state.booking.data.confirmation = value;
    },
    setBookingContactForInsurance(state, value) {
        state.booking.data.contactForInsurance = value;
    },
    setAfterLoginRedirect(state, afterLoginRedirect) {
      state.afterLoginRedirect = afterLoginRedirect;
    },
    resetBookingData(state) {
      state.booking = {
        prevUnlockedStep: 'bookingCollection',
        unlockedSteps: ['bookingCollection'],
        data: {
          collectionAddress: null,
          collectionTime: null,
          destinationAddress: null,
          packages: [],
          deliveryService: null,
          confirmation: null,
          contactConsent: null,
        },
      };
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    resetState(state) {
      state.user = null;
      state.addresses = null;
      state.booking = {
        prevUnlockedStep: 'bookingCollection',
        unlockedSteps: ['bookingCollection'],
        readyForPayment: false,
        data: {
          collectionAddress: null,
          collectionTime: null,
          destinationAddress: null,
          packages: [],
          deliveryService: null,
          confirmation: null,
          contactConsent: null,
        },
      };
      state.orders = null;
      state.transactions = null;
    },
    setLocales(state, locales) {
      state.locales = locales;
    },
    setReadyForPayment(state, readyForPayment)
    {
        state.booking.readyForPayment = readyForPayment;
    }
  },
  actions: {
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/auth/login', credentials)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              data = data.data;
              context.commit('setJwt', data.jwt);
              context.commit('setUser', data.user);
              localStorage.setItem('jwtToken', JSON.stringify(data.jwt));
              context.dispatch('autoRefreshJwt');

              resolve(response);
            } else {
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    register(context, userData) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/auth/register', userData)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              data = data.data;
              context.commit('setJwt', data.jwt);
              context.commit('setUser', data.user);

              context.dispatch('autoRefreshJwt');

              resolve(response);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changePassword(context, passwords) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/user/changePassword', passwords)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetPassword(context, data) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/auth/reset-password', data)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    refreshJwt(context, autoRefresh) {
      //console.log('Refreshing Token');
      //ajax.defaults.withCredentials = true;

      ajax
        .post('/auth/refresh')
        .then((response) => {
          let data = response.data;
          if(data.data != false)
          {
            data = data.data;
            context.commit('setJwt', data.jwt);
            context.commit('setUser', data.user);

            if (autoRefresh) {
              context.dispatch('autoRefreshJwt');
            }
          }
          else {
            context.commit('removeJwt');
            context.commit('removeUser');

            window.dispatchEvent(new Event('redirectToLogin'));
          }
        })
        .catch(() => {
          //console.error(error);
            context.commit('removeJwt');
            context.commit('removeUser');

            window.dispatchEvent(new Event('redirectToLogin'));
        });
    },
    autoRefreshJwt(context) {
      //console.log('Auto Refreshing Token');

      /* const now = Date.now() / 1000;
      let timeUntilRefresh = context.state.jwt.expires - now; */
      let timeUntilRefresh = context.state.jwt.expire;
      timeUntilRefresh -= 5 * 60; // Refresh 5 minutes before it expires
      clearTimeout(context.state.refreshTimeOutTask);
      const refreshTimeOutTask = setTimeout(() => context.dispatch('refreshJwt'), timeUntilRefresh * 1000);
      context.commit('setRefreshTimeOutTask', refreshTimeOutTask); // In case you want to cancel this task on logout
    },
    addAddress(context, address) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/address/add', address)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              context.commit('setAddresses', data.data.addresses);
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateAddress(context, address) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/address/edit', address)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              context.commit('setAddresses', data.data.addresses);
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAddress(context, address) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/address/delete', address)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              context.commit('setAddresses', data.data.addresses);
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAddresses(context, fresh) {
      fresh = fresh || false;

      return new Promise((resolve, reject) => {
        if (context.state.addresses == null || fresh) {
          ajax
            .post('address/list')
            .then((response) => {
              let data = response.data;
              if (data.data != false) {
                context.commit('setAddresses', data.data.addresses);
                resolve(data.data);
              } else {
                this.error = data.error.message;
                reject(data.error.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    fetchCountries(context, fresh) {
      fresh = fresh || false;

      return new Promise((resolve, reject) => {
        if (context.state.countries == null || fresh) {
          let countries = localStore.get('countries');
          let flagsBaseUrl = localStore.get('flagsBaseUrl');

          if (countries == null || flagsBaseUrl == null) {
            ajax
              .get('/general/countries')
              .then((response) => {
                const data = response.data.data;

                if (data.countries) {
                  context.commit('setCountries', data.countries);
                }

                if (data.flagPrefix) {
                  context.commit('setFlagsBaseUrl', data.flagPrefix);
                }

                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (countries) {
              context.commit('setCountries', countries);
            }

            if (flagsBaseUrl) {
              context.commit('setFlagsBaseUrl', flagsBaseUrl);
            }

            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    fetchUAEStates(context, fresh) {
      fresh = fresh || false;
      return new Promise((resolve, reject) => {
        if (context.state.uaeStates == null || fresh) {
          let states = localStore.get('uaeStates');

          if (states == null) {
            ajax
              .get('/general/states')
              .then((response) => {
                const data = response.data.data;

                if (data.uaeStates) {
                  context.commit('setUAEStates', data.uaeStates);
                }

                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (states) {
              context.commit('setUAEStates', states);
            }

            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    fetchPackageTypes(context, fresh) {
      fresh = fresh || false;

      return new Promise((resolve, reject) => {
        if (context.state.packageTypes == null || fresh) {
          let packageTypes = localStore.get('packageTypes');

          if (packageTypes == null) {
            ajax
              .get('/general/packageTypes')
              .then((response) => {
                const data = response.data.data;

                if (data.packageTypes) {
                  context.commit('setPackageTypes', data.packageTypes);
                }

                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (packageTypes) {
              context.commit('setPackageTypes', packageTypes);
            }

            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    logout(context) {
      context.commit('removeJwt');
      context.commit('removeUser');
      context.commit('resetState');
      clearTimeout(context.state.refreshTimeOutTask);

      return new Promise((resolve, reject) => {
        ajax
          .post('/auth/logout')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setSessionOnMarketingSite() {
      return new Promise((resolve, reject) => {
        ajax
          .post('http://sendit-wordpress.localhost/set-user.php', { isLoggedIn: 'yes', profileImage: 'sss' })
          .then((response) => {
            console.log('done', response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadPhoto(context, photo) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/user/updatePicture', photo, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              data = data.data;
              context.commit('setUser', data.user);
              resolve(response);
            } else {
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateProfile(context, profile) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/user/updateProfile', profile)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              data = data.data;
              context.commit('setUser', data.user);
              resolve(response);
            } else {
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setBookingCollectionAddress(context, collectionAddress) {
      context.commit('setBookingCollectionAddress', collectionAddress);
    },
    setBookingCollectionTime(context, collectionTime) {
      context.commit('setBookingCollectionTime', collectionTime);
    },
    setBookingDestinationAddress(context, destinationAddress) {
      context.commit('setBookingDestinationAddress', destinationAddress);
    },
    addUnlockedStep(context, step) {
      //console.log('unlock step: ' + step);
      context.commit('addUnlockedStep', step);
    },
    removeUnlockedStep(context, step) {
      context.commit('removeUnlockedStep', step);
    },
    goToNextBookingStepOld(context) {
      let allStepsComplete = true;
      for (var i in context.state.booking.data) {
        if (context.state.booking.data[i] == null || (Array.isArray(context.state.booking.data[i]) && context.state.booking.data[i].length == 0)) {
          allStepsComplete = false;
          let step = document
            .getElementById('booking_' + i)
            .closest('.booking-form-section')
            .getAttribute('id');
          context.commit('addUnlockedStep', step);
          scrollToElement(document.getElementById(step), 100);
          break;
        }
      }

      if (allStepsComplete) {
        context.commit('addUnlockedStep', 'bookingActions');
      }
    },
    goToNextBookingStep(context) {
      let steps = ['bookingCollection', 'bookingDestination', 'bookingPackages', 'bookingDeliveryService', 'bookingConfirmation', 'bookingActions'];
      let prevStepIndex = steps.indexOf(context.state.booking.prevUnlockedStep);
      let nextStep = steps[prevStepIndex + 1];

      let prevStepAlreadySet = true;
      document.querySelectorAll('#' + context.state.booking.prevUnlockedStep + ' .box').forEach((el) => {
        let id = el.getAttribute('id');
        if (id != null) {
          id = id.replace('booking_', '');
          if (context.state.booking.data[id] == null || (Array.isArray(context.state.booking.data[id]) && context.state.booking.data[id].length == 0)) {
            prevStepAlreadySet = false;
          }
        }
      });

      if (prevStepAlreadySet) {
        context.commit('addUnlockedStep', nextStep);

        //console.log('#' + nextStep + ' .box');
        let nextStepAlreadySet = true;
        document.querySelectorAll('#' + nextStep + ' .box').forEach((el) => {
          let id = el.getAttribute('id');
          if (id != null) {
            id = id.replace('booking_', '');
            if (context.state.booking.data[id] == null || (Array.isArray(context.state.booking.data[id]) && context.state.booking.data[id].length == 0)) {
              nextStepAlreadySet = false;
            }
          }
        });

        if (nextStepAlreadySet) {
          context.dispatch('goToNextBookingStep');
        }
      }
    },
    getQuote(context, params) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/general/getQuote', params)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              resolve(data.data.quotes);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setQuoteParams(context, params) {
      context.commit('setQuoteParams', params);
      localStorage.setItem('quoteParams', JSON.stringify(params));
    },
    setQuoteDeliveryService(context, deliveryService) {
      context.commit('setQuoteDeliveryService', deliveryService);
      localStorage.setItem('quoteDeliveryService', JSON.stringify(deliveryService));
    },
    setBookingDeliveryService(context, deliveryService) {
      context.commit('setBookingDeliveryService', deliveryService);
    },
    addBookingPackage(context, pkg) {
      context.commit('addBookingPackage', pkg);
    },
    updateBookingPackage(context, data) {
      context.commit('updateBookingPackage', data);
    },
    deleteBookingPackage(context, index) {
      context.commit('deleteBookingPackage', index);
    },
    setBookingConfirmation(context, value) {
      context.commit('setBookingConfirmation', value);
    },
    setBookingContactForInsurance(context, value)
    {
        context.commit('setBookingContactForInsurance', value);
    },
    resetBookingData(context) {
      context.commit('resetBookingData');
    },
    book(context, bookingDate) {
      return new Promise((resolve, reject) => {
        ajax
          .post('orders/add', bookingDate)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              data = data.data;
              resolve(data);
            } else {
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    validateCoupon(couponCode) {
      return new Promise((resolve, reject) => {
          ajax
              .post('coupon/get', { couponCode })
              .then((response) => {
                  let data = response.data;
                  console.log("data", data);
                  if (!data.error) {
                      resolve(data); // Resolve with the coupon data
                  } else {
                      reject(data.error.message); // Reject with the error message
                  }
              })
              .catch((error) => {
                  reject(error); // Reject with the error
              });
      });
  },
    contactForQuote(context, params) {
      return new Promise((resolve, reject) => {
        ajax
          .post('/general/createQuote', params)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              data = data.data;
              resolve(response);
            } else {
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setAfterLoginRedirect(context, afterLoginRedirect) {
      context.commit('setAfterLoginRedirect', afterLoginRedirect);
    },
    fetchOrders(context, fresh) {
      fresh = fresh || false;

      return new Promise((resolve, reject) => {
        if (context.state.orders == null || fresh) {
          ajax
            .post('orders/list')
            .then((response) => {
              let data = response.data;
              if (data.data != false) {
                context.commit('setOrders', data.data.orders);
                resolve(data.data);
              } else {
                this.error = data.error.message;
                reject(data.error.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    fetchTransactions(context, fresh) {
      fresh = fresh || false;

      return new Promise((resolve, reject) => {
        if (context.state.transactions == null || fresh) {
          ajax
            .post('transactions/list')
            .then((response) => {
              let data = response.data;
              if (data.data != false) {
                context.commit('setTransactions', data.data.transactions);
                resolve(data.data);
              } else {
                this.error = data.error.message;
                reject(data.error.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    fetchOrder(context, data) {
      return new Promise((resolve, reject) => {
        ajax
          .post('orders/get', data)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchTransaction(context, data) {
      return new Promise((resolve, reject) => {
        ajax
          .post('transactions/get', data)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchLocales(context, fresh) {
      fresh = fresh || false;

      return new Promise((resolve, reject) => {
        if (context.state.locales == null || fresh) {
          let locales = localStore.get('locales');
          if (locales == null) {
            ajax
              .get('general/locales')
              .then((response) => {
                let data = response.data;
                if (data.data != false) {
                  context.commit('setLocales', data.data.locales);
                  resolve(data.data);
                } else {
                  this.error = data.error.message;
                  reject(data.error.message);
                }
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            context.commit('setLocales', locales);
            resolve(locales);
          }
        } else {
          resolve(context.state.locales);
        }
      });
    },
    trackOrder(context, data) {
      return new Promise((resolve, reject) => {
        ajax
          .post('general/tracking', data)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              resolve(data.data);
            } else {
              this.error = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    readyForPayment(context, data)
    {
        context.commit('setReadyForPayment', data);
    }
  },
  getters: {
    loggedIn(state) {
      return state.jwt !== null && state.jwt.token !== null;
    },
    countryAddresses: (state) => (country) => {
      return state.addresses.filter((address) => {
        return address.countryCode == country;
      });
    },
    stateAddresses: (state) => (uaeState) => {
      return state.addresses.filter((address) => {
        return address.state == uaeState && address.countryCode == 'AE';
      });
    },
    addressById: (state) => (id) => {
      return state.addresses.find((address) => {
        return address.id == id;
      });
    },
    getLocale: (state) => (key) => {
      if (state.locales == null || state.locales == '') {
        return '';
      }

      if (typeof state.locales[key] != 'undefined') {
        return state.locales['key'];
      } else {
        return '';
      }
    },
  },
});
