<template>
  <Header />
  <QuoteNBook />
  <div class="page-bg">
    <div class="main">
      <router-view />
    </div>
    <Footer />
    <SuccessMessages />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import QuoteNBook from '@/components/QuoteNBook.vue';
import SuccessMessages from './components/SuccessMessages.vue';

export default {
  name: 'App',
  components: {
    Header,
    QuoteNBook,
    Footer,
    SuccessMessages,
  },
  created() {


    window.addEventListener('redirectToLogin', () => {
        if(this.$route.meta.requiresAuth)
        {
            this.$router.push({ path: '/login' });
        }
    });


    window.addEventListener('router.push', (e) => {
        this.$router.push({ path: e.detail.path, query: e.detail.query });
        
        /* if (typeof this.$route.query.redirect != 'undefined') {
        this.$store.dispatch('setAfterLoginRedirectPath', this.$route.query.redirect);
        } */

        if (this.$route.path == '/quote') {
        window.dispatchEvent(new Event('refreshQuote'));
        }
    });

    const { jwt } = this.$store.state;
    const now = Date.now() / 1000;
    if (jwt == null || now > jwt.expires) {
      this.$store.dispatch('refreshJwt', true);
    } else {
      this.$store.dispatch('autoRefreshJwt');
    }

    this.$store.dispatch('fetchLocales');
  },
  /* mounted() {
    
  }, */
  /* watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Default Title';
            }
        },
    }, */
};
</script>

<style lang="scss" src="./scss/app.scss"></style>

<!--<style lang="scss">
@import url('scss/app.scss');
</style>-->
