<template>
    <div class="success-messages">
        <transition appear name="fade" v-for="(message, index) in messages" :key="index">
        <div class="message-wrap" v-if="message.show" :class="{'new': message.new}">
            <div class="message">
                {{ message.text }}
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "SuccessMessages",
    data() {
        return {
            messages: [],
        };
    },
    mounted() {
        window.addEventListener("addSuccessMessage", (e) => {
            let message = {'text': e.detail.message, 'show': false, /* 'new': true */};
            this.messages.push(message);
            message.show = true;
            //this.messages[this.messages.length - 1].show = true;
            /* setTimeout(() => {
                message.new = false;
            }, 1000); */

            setTimeout(() => {
                this.messages[0].show = false;
                setTimeout(() => {
                    this.messages.shift();
                }, 200);
            }, 10000);
        });
    },
    methods: {},
};
</script>

<style lang="scss">
@import '../scss/partials/variables.scss';

.fade-enter-active,
.fade-leave-active {
  max-height: 48px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0px;
}

.success-messages
{
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    pointer-events: none;


    .message-wrap {
        transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out, border-color 500ms ease-in-out;
        overflow: hidden;
        box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.16);
        border-radius: 50px;
        border: solid 2px #fff;
        margin: 10px 0px;

        &:first-child {
            margin-bottom: 20px;
        }

        /* &.new {
            border-color: #5b812c;
        } */
    }

    .message {
        background: #FCF9F1;
        background-image: url($srcPath + 'assets/green-success-icon.svg');
        background-repeat: no-repeat;
        background-position: left 15px center;
        padding: 12px 15px 12px 35px;
        line-height: 1.5;
        font-size: 13px;
        color: #5b812c;
        display: inline-block;
        transition: all 200ms ease-in-out;

        /* &.new {
            background-image: url($srcPath + 'assets/white-success-icon.svg');
            background-color: #5b812c;
            color: #FFF;
        } */
    }
}

</style>