import axios from "axios";

/* axios.defaults.baseURL = 'https://admin-sendit.bluebeetle.net/api/v1/';

(function() {
  const jwt = localStorage.getItem('jwt');
  console.log(jwt);
  if (jwt) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${jwt.token}`;
  } else {
      axios.defaults.headers.common['Authorization'] = null;
      //if setting null does not remove `Authorization` header then try delete axios.defaults.headers.common['Authorization'];
  }
})(); */


//axios.defaults.withCredentials = true;

const ajax = axios.create({
  baseURL: (process.env.VUE_APP_API_BASE_URL !== undefined) ? process.env.VUE_APP_API_BASE_URL : '/',
  withCredentials: true
})

ajax.CancelToken = axios.CancelToken
ajax.isCancel = axios.isCancel

/*
* The interceptor here ensures that we check for the token in local storage every time an ajax request is made
*/
ajax.interceptors.request.use(
 (config) => {
   let jwt = JSON.parse(localStorage.getItem('jwt'));
   if (jwt) {
     config.headers['Authorization'] = `Bearer ${ jwt.token }`
   }

   return config
 },

 (error) => {
   return Promise.reject(error)
 }
)

export default ajax
