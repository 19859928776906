window.handleErrors = (fn) => {
  return function(...params) {
    return fn(...params).catch((err) => {
      console.error(err);
    });
  };
};

/* ---------------------------- */

window.disableBodyScrolling = () => {
  /* var scrollPosition = [
    self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
    self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
  ]; */

  const body = document.querySelector('body');
  //body.setAttribute('scroll-position', scrollPosition);
  //window.scrollTo(scrollPosition[0], scrollPosition[1]);
  body.classList.add('disable-scrolling');

  /* if (util.isBodyOverflowing()) {
    $('body, .ebsb').css('padding-right', util.getScrollBarWidth());
  } */
};

/* ---------------------------- */

window.enableBodyScrolling = () => {
  /* $('body, .ebsb')
    .css('padding-right', ''); */

  let body = document.querySelector('body');
  body.classList.remove('disable-scrolling');
  //var scrollPosition = body.getAttribute('scroll-position').split(',');
  //window.scrollTo(scrollPosition[0], scrollPosition[1]);
};

/* ---------------------------- */

window.arrayUniqueFilter = (value, index, self) => {
  return self.indexOf(value) === index;
};

/* ---------------------------- */

window.localStore = {
  set(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  },
  get(key) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
};

/* ---------------------------- */

window.scrollToElement = (el, offset) => {
  window.scrollTo({
    top: el.offsetTop - offset,
    behavior: 'smooth',
  });
}

/* ---------------------------- */


window.debounce = (func, wait, immediate) => {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/* ---------------------------- */


window.isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}


/* ---------------------------- */


window.isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
