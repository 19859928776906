
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import './axios';
import './globalFunctions';

// For production only //
console.error = () => {};
// -------------------- //

createApp(App).use(store).use(router).mount("#app");

/* window.addEventListener('disableScrolling', (e) => {
  disableBodyScrolling();
});

window.addEventListener('enableScrolling', (e) => {
  enableBodyScrolling();
}); */
